<template>
  <div
    v-if="!$route.meta.fullscreen"
    class="flex h-screen bg-gray-50 dark:bg-gray-900"
  >
    <Aside />
    <div class="flex flex-col flex-1">
      <Header />
      <main class="h-full pb-16 overflow-y-auto">
        <div class="container px-6 mx-auto grid">
          <router-view />
        </div>
      </main>
    </div>
  </div>
  <div
    v-else
    class="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900"
  >
    <router-view />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Aside from "@/components/Aside";
export default {
  components: { Aside, Header }
};
</script>

<style lang="sass">
@import "src/assets/index"

html
  font-family: "bruh-font", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility
</style>

import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
const cookieAuthName = "ART_PRISE_ADMIN_AUTH";

/**
 * Get token.
 */
const getToken = () => {
  return Cookies.get(cookieAuthName);
};

/**
 * Set new token.
 * @param token New token.
 */
const setToken = token => {
  Cookies.set(cookieAuthName, token);
};

/**
 * Remove token from cookies.
 */
const deleteToken = () => {
  Cookies.remove(cookieAuthName);
};

/**
 * Check if user are authenticated.
 * @returns boolean
 */
const isAuthenticated = async () => {
  try {
    const jwt = await jwt_decode(getToken());

    // check expiration
    if (Math.floor(Date.now() / 1000) > jwt.exp) {
      deleteToken();
      return false;
    }

    return !!jwt;
  } catch (e) {
    return false;
  }
};

/**
 * Retrieve user information from token.
 * @return Object
 */
const getUser = async () => {
  try {
    return await jwt_decode(getToken());
  } catch (e) {
    return null;
  }
};

export default { getToken, setToken, deleteToken, isAuthenticated, getUser };

<template>
  <aside
    class="z-20 flex-shrink-0 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block"
  >
    <div class="py-4 text-gray-500 dark:text-gray-400">
      <router-link
        to="/"
        class="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
      >
        Art Prise - admin
      </router-link>
      <ul class="mt-6">
        <li class="relative px-6 py-3">
          <router-link
            to="/"
            class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
          >
            <span
              class="span-gou absolute inset-y-0 left-0 w-1 bg-blue-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
            <span class="ml-4">Menu</span>
          </router-link>
        </li>
      </ul>
      <ul>
        <li class="relative px-6 py-3">
          <router-link
            to="/accounts"
            class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
          >
            <span
              class="span-gou absolute inset-y-0 left-0 w-1 bg-blue-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
            <span class="ml-4">Utilisateurs</span>
          </router-link>
        </li>
        <li class="relative px-6 py-3">
          <router-link
            to="/categories"
            class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
          >
            <span
              class="span-gou absolute inset-y-0 left-0 w-1 bg-blue-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
            <span class="ml-4">Catégories</span>
          </router-link>
        </li>
        <li class="relative px-6 py-3">
          <router-link
            to="/directory-types"
            class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
          >
            <span
              class="span-gou absolute inset-y-0 left-0 w-1 bg-blue-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
            <span class="ml-4">Catégories d'annuaire</span>
          </router-link>
        </li>
        <li class="relative px-6 py-3">
          <router-link
            to="/directories"
            class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
          >
            <span
              class="span-gou absolute inset-y-0 left-0 w-1 bg-blue-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
            <span class="ml-4">Annuaire</span>
          </router-link>
        </li>
        <li class="relative px-6 py-3">
          <router-link
            to="/ambassadors"
            class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
          >
            <span
              class="span-gou absolute inset-y-0 left-0 w-1 bg-blue-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
            <span class="ml-4">Ambassadeurs</span>
          </router-link>
        </li>
        <li class="relative px-6 py-3">
          <router-link
            to="/prises"
            class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
          >
            <span
              class="span-gou absolute inset-y-0 left-0 w-1 bg-blue-600 rounded-tr-lg rounded-br-lg"
              aria-hidden="true"
            ></span>
            <span class="ml-4">Prises</span>
          </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Aside"
};
</script>

<style lang="scss">
.span-gou {
  display: none;
}
.router-link-exact-active {
  > .span-gou {
    display: block;
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

Vue.component("datetime", Datetime);
Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);

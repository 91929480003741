import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/sign-in",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "SignIn" */ "../views/SignIn.vue"),
    meta: { fullscreen: true }
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
    meta: { fullscreen: false }
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: () =>
      import(
        /* webpackChunkName: "Accounts" */ "../views/accounts/Accounts.vue"
      ),
    meta: { fullscreen: false }
  },
  {
    path: "/account/:id",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/accounts/Account.vue"),
    meta: { fullscreen: false }
  },
  {
    path: "/categories",
    name: "Categories",
    component: () =>
      import(
        /* webpackChunkName: "Categories" */ "../views/categories/Categories.vue"
      ),
    meta: { fullscreen: false }
  },
  {
    path: "/directory-types",
    name: "DirectoryTypes",
    component: () =>
      import(
        /* webpackChunkName: "DirectoryTypes" */ "../views/directory-types/DirectoryTypes.vue"
      ),
    meta: { fullscreen: false }
  },
  {
    path: "/directories",
    name: "Directories",
    component: () =>
      import(
        /* webpackChunkName: "Directories" */ "../views/directories/Directories.vue"
      ),
    meta: { fullscreen: false }
  },
  {
    path: "/directories/:id",
    name: "Directory",
    component: () =>
      import(
        /* webpackChunkName: "Account" */ "../views/directories/Directory.vue"
      ),
    meta: { fullscreen: false }
  },
  {
    path: "/ambassadors",
    name: "Ambassadors",
    component: () =>
      import(
        /* webpackChunkName: "Account" */ "../views/ambassadors/Ambassadors.vue"
      ),
    meta: { fullscreen: false }
  },
  {
    path: "/interview/:id",
    name: "Interview",
    component: () =>
      import(
        /* webpackChunkName: "Account" */ "../views/ambassadors/Interview.vue"
      ),
    meta: { fullscreen: false }
  },
  {
    path: "/prises",
    name: "Prises",
    component: () =>
      import(/* webpackChunkName: "Prises" */ "../views/prises/Prises.vue"),
    meta: { fullscreen: false }
  },
  {
    path: "/prise/:id",
    name: "Prise",
    component: () =>
      import(/* webpackChunkName: "Prise" */ "../views/prises/Prise.vue"),
    meta: { fullscreen: false }
  }
];

const router = new VueRouter({
  routes
});

// guard
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = await auth.isAuthenticated();
  if (!isAuthenticated && to.name !== "SignIn") {
    next({ name: "SignIn" });
  } else if (isAuthenticated && to.name === "SignIn") {
    next({ name: from.name });
  } else {
    next();
  }
});

export default router;
